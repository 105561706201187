import React from 'react'
import { Router } from "@reach/router"
import LoginForm from '../../components/forms/LoginForm'
import GuestRoute from '../../components/guestRoute'

export default function Login() {
  return (
    <Router>
      <GuestRoute path="/login" component={LoginForm}/>
    </Router>
  )
}
