import React from "react"
import { navigate } from "gatsby"
import { useAuth } from "../hooks/useAuth";
const GuestRoute = ({ component: Component, location, ...rest }) => {

  const isBrowser = () => typeof window !== "undefined"
  const { isLoggedIn } = useAuth();

  if (isLoggedIn && location.pathname === `/login`) {
    isBrowser() && navigate("/user")
    return null
  }

  return <Component {...rest} />
}
export default GuestRoute
