import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useAppState } from '../context';
import Layout from '../Layout';
import ClipLoader from 'react-spinners/ClipLoader'
import ArrowRight from '../../images/icons/chevron-right.svg'
import Helmet from 'react-helmet'
import CookieBar from "../../components/CookieBar";
export default function LoginForm() {

  const [user, setUser] = useState({ email: '', password: '', rememberMe: false });
  const { login, error, status, logout } = useAuth();
  const { isLoggedIn } = useAppState();

  const submit = (e) => {
    e.preventDefault();
    login(user.email, user.password, user.rememberMe);
  }

  return (
    <Layout>
      <Helmet>
        <title>Pausit | Logga in</title>
      </Helmet>
      <div className="login-form">
        <h2>Logga in på mina sidor</h2>

        <form onSubmit={(e) => !isLoggedIn ? submit(e) : logout()}>
          <div className="form-row">
            <label htmlFor="email">E-postadress</label>
            <input onChange={(e) => setUser({ ...user, email: e.target.value })} type="email" name="email" id="email" autoComplete="off" disabled={status === 'resolving' && true} required />
          </div>
          <div className="form-row">
            <label htmlFor="password">Lösenord</label>
            <input onChange={(e) => setUser({ ...user, password: e.target.value })} type="password" name="password" id="password" autoComplete="off" disabled={status === 'resolving' && true} required />
          </div>
          <div className="check-row">
            <label htmlFor="remember" className="checkbox-container">
              Kom ihåg mig
              <input onChange={(e) => setUser({ ...user, rememberMe: e.target.value })} type="checkbox" name="remember" id="remember" />
              <span className="checkmark"></span>
            </label>
          </div>
          <button type="submit" disabled={status === 'resolving'}>
            Logga in
            <img src={ArrowRight} alt="arrow" />
          </button>
        </form>

        <div className="messages">
          {status === 'resolving' &&
            <div className="text-center spinner">
              <ClipLoader size={22} color={"#ffffffbb"} />
            </div>
          }
          {error && (
            <div className="error-notice text-center">
              <p>{error}</p>
            </div>
          )}
        </div>
        <Link to="/forgotpassword" className="hover-underline">Glömt lösenord</Link>
      </div>
      {/* <CookieBar /> */}
    </Layout>
  )
}
